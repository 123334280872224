import React from "react";
import {
  RealmAppProvider,
  FACILITATOR_APP_ID,
} from "../../components/realm_app";
import OnboardingRedirector from "../../components/facilitator/onboarding_redirector";
import ForgotPasswordComponent from "../../components/forgot_password";
import "../../index.css";

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <ForgotPasswordComponent home_uri="/facilitator/login/" />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
